import React, { useState } from 'react'

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';


const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
  };

  return (
    <>
    <h2 className="head-text">お問い合わせ</h2>

    <div className="app__footer-cards">
      <div className="app__footer-card ">
        <img src={images.email} alt="email" />
        <a href="mailto:takanashikazuma.job@gmail.com" className="p-text">takanashikazuma.job@gmail.com</a>
      </div>
    </div>
      {!isFormSubmitted ? (
      <div className="app__footer-form app__flex">
        <div className="app__flex">
          <input className="p-text" type="text" placeholder="お名前" name="username" value={username} onChange={handleChangeInput} />
        </div>
        <div className="app__flex">
          <input className="p-text" type="email" placeholder="メールアドレス" name="email" value={email} onChange={handleChangeInput} />
        </div>
        <div>
          <textarea className="p-text" placeholder="本文" value={message} name="message" onChange={handleChangeInput} />
        </div>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? '送信' : '送信中...'}</button>
      </div>
      ) : (
      <div>
        <h3 className="head-text">
          ご連絡ありがとうございます！
        </h3>
      </div>
      )}
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
